/* eslint-disable react/display-name */
import React from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'

import { I18nConsumer } from './index'

const LanguageComponent = ({ availableLanguages, children, language, originalPath }) => {
  const handleChangeLanguage = (newLanguage) => {
    const newUrl = `/${newLanguage}${originalPath}`
    navigate(newUrl)
  }

  return children({
    language,
    changeLanguage: handleChangeLanguage,
    availableLanguages,
  })
}

LanguageComponent.propTypes = {
  availableLanguages: PropTypes.array.isRequired,
  originalPath: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
}

const Language = (props) => {
  return (
    <I18nConsumer>
      {(lngProps) => <LanguageComponent {...lngProps} {...props} />}
    </I18nConsumer>
  )
}

export default Language
