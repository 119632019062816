import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { I18nextProvider } from 'react-i18next'
import { I18nProvider } from './index'

import setupI18next from './setupI18next'

export const withI18next = (options = {}) => (Comp) => {
  class I18n extends Component {
    constructor(props) {
      super(props)

      const { pageContext } = props

      this.i18n = setupI18next(
        pageContext.fallbackLanguage,
        pageContext.i18nextOptions,
      )
      this.activateLanguage()
    }

    activateLanguage() {
      const { data, pageContext } = this.props

      if (data && data.locales) {
        data.locales.edges.forEach(({ node }) => {
          const { language, ns, data } = node
          if (!this.i18n.hasResourceBundle(language, ns)) {
            this.i18n.addResourceBundle(language, ns, JSON.parse(data), true)
          }
        })
      }

      this.i18n.changeLanguage(pageContext.language)
    }

    componentDidUpdate(prevProps) {
      if (this.props.pageContext.language !== prevProps.pageContext.language) {
        this.activateLanguage()
      }
    }

    render() {
      return (
        <I18nextProvider i18n={this.i18n}>
          <I18nProvider {...this.props.pageContext}>
            <Comp {...this.props} />
          </I18nProvider>
        </I18nextProvider>
      )
    }
  }

  I18n.propTypes = {
    data: PropTypes.object.isRequired,
    pageContext: PropTypes.object.isRequired,
  }

  return I18n
}

export default withI18next
