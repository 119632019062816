/* eslint-disable react/display-name */
import React from 'react'
import PropTypes from 'prop-types'
import { Link as GatsbyLink } from 'gatsby'

import { I18nConsumer } from './index'

const Link = ({ to, language, children, ...rest }) => {
  return (
    <GatsbyLink to={language ? `/${language}${to}` : `${to}`} {...rest}>
      {children}
    </GatsbyLink>
  )
}

Link.propTypes = {
  to: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default (props) => {
  return (
    <I18nConsumer>
      {({ language }) => <Link language={language} {...props} />}
    </I18nConsumer>
  )
}
