import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { lookup, navigatorLanguages } from 'langtag-utils'

import { isBrowser } from './utils'

class Redirect extends PureComponent {
  componentDidMount() {
    if (!isBrowser()) this.perform()
  }

  perform() {
    const {
      fallbackLanguage,
      availableLanguages,
      redirectPage,
    } = this.props.pageContext

    let language = window.localStorage.getItem('language')

    if (typeof language === 'undefined' || language) {
      language = lookup(
        availableLanguages,
        navigatorLanguages(),
        fallbackLanguage,
      )
    }

    if (!availableLanguages.includes(language)) {
      language = lookup(
        availableLanguages,
        navigatorLanguages(),
        fallbackLanguage,
      )
    }

    window.localStorage.setItem('language', language)

    const newUrl = `/${language}${redirectPage}`
    navigate(newUrl, { replace: true })
  }

  render() {
    return null
  }
}

Redirect.propTypes = {
  pageContext: PropTypes.shape({
    fallbackLanguage: PropTypes.string.isRequired,
    availableLanguages: PropTypes.array.isRequired,
    redirectPage: PropTypes.string.isRequired,
  }),
}

export default Redirect
