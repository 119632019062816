import i18next from 'i18next'

function setupI18next(fallbackLanguage, i18nextOptions) {
  i18next.init({
    debug: process.env.NODE_ENV !== 'production',
    defaultNS: 'messages',
    fallbackLng: fallbackLanguage,
    react: {
      useSuspense: false,
    },
    ...i18nextOptions,
  })

  return i18next
}

export default setupI18next
