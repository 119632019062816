import React from 'react'
import PropTypes from 'prop-types'

export const I18nContext = React.createContext()
I18nContext.displayName = 'I18nContext'

export function I18nProvider({ children, ...rest }) {
  return (
    <I18nContext.Provider value={{ ...rest }}>{children}</I18nContext.Provider>
  )
}

I18nProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const I18nConsumer = I18nContext.Consumer
